import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { FileInfoV2 } from '@/services/SearchService';

import { FitView, ZoomConfig } from '../../constants';
import { trimSearchTermForPdf } from '../../helpers/utils';
import { FitHeightIcon, FitOriginIcon, FitWidthIcon } from '../icons';
import { NumericInput } from '../inputs/NumericInput';
import { SearchTerm } from '../pdf/pdf';
import DownloadButton from './DownloadButton';
import { HighlightedKeyword } from './HighlightedKeyword';
import { HoverableDetailTitle } from './HoverableDetailTitle';
import ShareDocumentButton from './ShareDocumentButton';
import View3DModelButton from './View3DModelButton';

interface DetailHeaderProps {
  disabled?: boolean;
  file: FileInfoV2;
  findKeywordIndex: Record<string, number>;
  fitTypeProp?: string;
  handleFitViewerCallback: (type: string) => void;
  handleNextHighlight: (keyword: string) => MouseEventHandler;
  handlePreviousHighlight: (keyword: string) => MouseEventHandler;
  onHide: () => void;
  onScalePercentageChange: (value: number) => void;
  pdfMatchedSearchTerms?: Record<string, number>;
  pdfOrderedMatchedSearchTerms?: SearchTerm[];
  scale: number | undefined;
  selectedKeywords: Record<string, boolean>;
  toggleKeyword: (keyword: string) => MouseEventHandler;
}

export function DetailHeader(props: DetailHeaderProps) {
  const {
    disabled = false,
    file,
    findKeywordIndex,
    fitTypeProp,
    handleFitViewerCallback,
    handleNextHighlight,
    handlePreviousHighlight,
    onScalePercentageChange,
    pdfMatchedSearchTerms,
    pdfOrderedMatchedSearchTerms,
    scale,
    selectedKeywords,
    toggleKeyword,
  } = props;

  const { t } = useTranslation();
  const [fitType, setFitType] = useState<string>();

  useEffect(() => {
    const defaultFitType = FitView.ORIGIN;
    setFitType(defaultFitType);
  }, [fitTypeProp]);

  const handleFitViewer = (type: string) => () => {
    setFitType(type);
    handleFitViewerCallback(type);
  };

  const formatZoomPercentage = useCallback((value: number) => `${value}%`, []);

  return (
    <Modal.Header>
      <Modal.Title
        className="modal-title-fluid"
        id="contained-modal-title-vcenter"
      >
        <div className="title">
          <HoverableDetailTitle
            file={file}
            onHide={props.onHide}
            title={file.title ?? t('document_preview_text_untitled')}
          />
        </div>

        <div className={twMerge('toolbar', disabled && 'disabled')}>
          <div className="selected-keywords">
            {pdfOrderedMatchedSearchTerms &&
              pdfOrderedMatchedSearchTerms.length > 0 && (
                <div className="keyword">
                  <div className="content">
                    <div className="s-tags custom">
                      {pdfOrderedMatchedSearchTerms.map(
                        (item: SearchTerm, index: number) => {
                          const trimmedTerm = trimSearchTermForPdf(item.term);

                          return (
                            <HighlightedKeyword
                              color={item.color}
                              findKeywordIndex={findKeywordIndex}
                              formattedKeyword={trimmedTerm}
                              handleNextHighlight={handleNextHighlight}
                              handlePreviousHighlight={handlePreviousHighlight}
                              index={index}
                              key={index}
                              keyword={item.term}
                              selectedKeywords={selectedKeywords}
                              toggleKeyword={toggleKeyword}
                              total={
                                pdfMatchedSearchTerms
                                  ? pdfMatchedSearchTerms[trimmedTerm]
                                  : 0
                              }
                            />
                          );
                        },
                      )}
                    </div>
                  </div>
                </div>
              )}

            <div className="ms-auto flex gap-2">
              <View3DModelButton
                className="ml-2"
                documentNo={file.document_number}
                documentType={file.type_of_document ?? ''}
              />
              <DownloadButton
                documentId={file.uuid}
                documentNo={file.document_number}
              />
              <ShareDocumentButton
                documentId={file.uuid}
                documentNo={file.document_number}
              />
            </div>
          </div>

          <div className="fit-viewer">
            <div className="ms-4">
              <FitOriginIcon
                className={fitType === FitView.ORIGIN ? 'ic active' : 'ic'}
                onClick={handleFitViewer(FitView.ORIGIN)}
              />
            </div>

            <div className={'px-4'}>
              <FitWidthIcon
                className={fitType === FitView.WIDTH ? 'ic active' : 'ic'}
                onClick={handleFitViewer(FitView.WIDTH)}
              />
            </div>

            <div className="me-4">
              <FitHeightIcon
                className={fitType === FitView.HEIGHT ? 'ic active' : 'ic'}
                onClick={handleFitViewer(FitView.HEIGHT)}
              />
            </div>
          </div>

          <div className="numeric-input">
            <NumericInput
              defaultValue={ZoomConfig.DEFAULT}
              formatter={formatZoomPercentage}
              max={ZoomConfig.MAX}
              min={ZoomConfig.MIN}
              onChange={onScalePercentageChange}
              slideStep={ZoomConfig.SLIDE_STEP}
              step={ZoomConfig.STEP}
              value={scale}
            />
          </div>
        </div>
      </Modal.Title>
    </Modal.Header>
  );
}
