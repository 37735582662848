import { ThreeDRotation } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import unlock3DLiteService from '@/services/Unlock3DLiteService';

interface View3DModelButtonProps {
  className?: string;
  documentNo: string;
  documentType: string;
}

const View3DModelButton: React.FC<View3DModelButtonProps> = ({
  className,
  documentNo,
  documentType,
}) => {
  const [modelUrl, setModelUrl] = useState<null | string>(null);
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useRef(false);

  useEffect(() => {
    if (documentType !== 'DP - PFD-PID DIAGRAMS') {
      return;
    }
    if (!isMounted.current) {
      const check3DModelAvailability = async () => {
        try {
          const response = await unlock3DLiteService.urlLookup(documentNo);
          if (response.status === 200) {
            const modelData = response.data.find(
              (item: { value: string }) => item.value === documentNo,
            );
            if (modelData) {
              setModelUrl(modelData.url);
            }
            setHasAccess(true);
          }
        } catch (error: any) {
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 403)
          ) {
            setHasAccess(false);
          } else {
            console.error('Error checking 3D model availability', error);
          }
        }
      };

      check3DModelAvailability();
      isMounted.current = true;
    }
  }, [documentNo, documentType]);

  const handleView3DModel = () => {
    if (modelUrl) {
      window.open(modelUrl, '_blank');
    } else {
      enqueueSnackbar('3D model not available', { variant: 'error' });
    }
  };

  if (!hasAccess || !modelUrl) {
    return null;
  }

  return (
    <div className="flex">
      <Button
        className={twMerge(
          'flex h-8 items-center gap-1 !bg-[#666] px-3 py-1 !text-sm !font-bold !normal-case text-white hover:!bg-[#333] active:!bg-[#111]',
          className,
        )}
        onClick={handleView3DModel}
        startIcon={<ThreeDRotation />}
      >
        View 3D Model
      </Button>
    </div>
  );
};

export default View3DModelButton;
