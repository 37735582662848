import axios from 'axios';

import { msalInstance } from '@/helpers/msal';

class Unlock3DLiteService {
  async getAccessTokenAsync(): Promise<string> {
    const userAccount = msalInstance.getActiveAccount()!;

    const tokenRequest = {
      scopes: [process.env.REACT_APP_U3D_API_SCOPE ?? ''],
    };

    try {
      const res = await msalInstance.acquireTokenSilent({
        ...tokenRequest,
        account: userAccount,
      });
      return res.accessToken;
    } catch (error) {
      console.error(error);
      const res = await msalInstance.acquireTokenPopup({
        ...tokenRequest,
        redirectUri: '/blank.html',
      });
      return res.accessToken;
    }
  }

  async urlLookup(documentNo: string) {
    const accessToken = await this.getAccessTokenAsync();
    const response = await axios.get(
      `${process.env.REACT_APP_U3D_BASE_API_URL}/secure/url_lookup/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          value: documentNo,
        },
      },
    );
    return response;
  }
}

const unlock3DLiteService = new Unlock3DLiteService();
export default unlock3DLiteService;
